/* ResetPassword.module.css */

/* General styling */
.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 1rem;
}

.container {
  display: flex;
  width: 90%;
  max-width: 1200px;
  min-height: 650px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.leftPanel {
  flex: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.skeletonImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  transition: background-image 0.5s ease-in-out;
}

.rightPanel {
  flex: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.resetPasswordCard {
  max-width: 250px;
  width: 100%;
  text-align: center;
}

.newLogo {
  margin: 0 auto 1rem;
}

.newLogo img {
  width: 150px;
  height: 75px;
}

.resetPasswordCard h2 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #5e005e;
  margin-bottom: 1rem;
}

.input {
  padding: 0.6rem;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Inter', sans-serif;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0; /* Removed margin for minimal spacing */
}

.resetBtn {
  background-color: #5e005e;
  color: #fff;
  padding: 0.6rem;
  font-size: 0.75rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 0; /* Removed margin for minimal spacing */
}

.resetBtn:hover {
  background-color: #4e004e;
}

.errorMessage {
  color: red;
  font-size: 0.75rem;
  margin-top: 1rem;
}

.message {
  color: #5e005e;
  font-size: 0.75rem;
  margin-top: 1rem;
}
