/* Apply box-sizing globally to all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Ensure no scroll on the entire page */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Main content wrapper - no padding or margin */
.main-content, .main-container {
  height: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Video room container */
.video-container {
  flex: 1;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: stretch;
  align-content: center;
  background-color: #f8f9fa;
  overflow: hidden;
  padding: 10px;
}

/* Video tile styling */
.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Placeholder styling for video tile */
.video-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: url('../assets/videoroom-placeholder.svg');
  background-size: cover;
  background-position: center;
}

.video-placeholder-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.video-element {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #f1f1f1;
  object-fit: cover;
}

/* Name label styling */
.name-label {
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
}

/* Button Container */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 5px;
  gap: 15px;
}

/* Individual Button Styling */
.button-container button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Icon styling */
.button-container button i {
  font-size: 20px;
}

/* Off icon styling (for muted or off states) */
.off-icon {
  position: relative;
}

.off-icon::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  top: 50%;
  left: 0;
  transform: rotate(45deg);
}

/* Pagination buttons integrated with the same button container */
.button-container button.pagination-btn {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
}

.button-container button.pagination-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Pagination number styling */
.button-container span {
  font-size: 16px;
  align-self: center;
}

/* Arrow icon styles */
.button-container button.pagination-btn i {
  font-size: 20px;
}
