/* Import Inter Medium font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

/* Sidebar container */
.sidebar {
  background-color: #ffffff !important;
  color: rgb(33, 31, 113);
  transition: width 0.3s ease;
  height: 100vh;
  position: relative;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  border-right: 1px solid #d6d6d6 !important;
}

/* Sidebar width classes */
.sidebar.collapsed {
  width: 71px !important;
}

.sidebar.expanded {
  width: 205px;
}

/* Sidebar Content Container for Reduced Padding */
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
}

/* Logo Container */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 0;
}

/* Center logo when collapsed with padding for alignment */
.collapsed .logo-container {
  padding: 15px 0;
  justify-content: center;
}

/* Divider Line after Logo */
.logo-divider {
  border: 0;
  height: 1px;
  background: #d6d6d6 !important;
  margin: 10px 0;
  transition: margin 0.3s ease;
}

/* Expanded Logo */
.expanded-logo {
  width: 150px;
  height: 75px;
  object-fit: contain;
  border-radius: 0;
  transition: none;
}

/* Collapsed Logo with smaller size */
.collapsed-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  transition: none;
}

/* Collapse Button */
.collapse-btn {
  display: flex;
  position: absolute;
  top: 80px;
  right: -20px;
  width: 30px;
  height: 30px;
  background-color: #ffffff !important; /* Ensures full opacity background */
  opacity: 1; /* Sets full opacity */
  border: none;
  color: #d6d6d6 !important; /* Icon color */
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
  z-index: 2;
}

/* Navigation Links */
.nav-links {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  flex-grow: 1;
}

/* Section header with center alignment in collapsed state */
.section-header {
  padding: 3px 0;
  font-size: 0.6rem;
  color: #757575 !important;
  text-transform: uppercase;
  transition: opacity 0.3s ease;
  font-family: 'Inter', sans-serif;
  text-align: left;
}

.collapsed .section-header {
  text-align: center;
}

/* Center-align items when sidebar is collapsed */
.nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #757575 !important;
  text-decoration: none;
  transition: background 0.2s;
  justify-content: flex-start;
  font-family: 'Inter', sans-serif;
  border-radius: 8px; /* Rounded corners */
}

.nav-item:hover {
  background-color: #d6d6d6 !important; /* Hover background color */
  color: #000000 !important; /* Hover font and icon color */
}

.collapsed .nav-item {
  justify-content: center;
}

/* Adjust icon spacing */
.nav-icon {
  font-size: 1.2rem;
  margin-right: 10px;
  transition: none;
}

.collapsed .nav-icon {
  margin-right: 0;
}

/* Hide text completely when collapsed */
.nav-item span {
  display: inline;
  transition: none;
}

.collapsed .nav-item span {
  display: none;
}

/* Divider Line */
.divider {
  border: 0;
  height: 1px;
  background: #d6d6d6 !important;
  margin: 15px 0;
  transition: margin 0.3s ease;
}

/* User Profile at Bottom */
.user-profile {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #d6d6d6 !important;
  margin-top: auto;
  transition: padding 0.3s ease;
  justify-content: flex-start;
}

.collapsed .user-profile {
  justify-content: center;
}

/* Avatar without scaling */
.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: none;
}

/* User info hidden in collapsed view */
.user-info {
  margin-left: 10px;
  transition: none;
  font-family: 'Inter', sans-serif;
}

.collapsed .user-info {
  display: none;
}

.user-name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #757575 !important;
}

.user-email {
  font-size: 0.75rem;
  color: #757575 !important;
}

/* Guest label and auth links */
.guest-label {
  color: #757575 !important;
}

.auth-links .auth-link {
  color: #757575 !important;
}

.auth-links span {
  color: #757575 !important;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: absolute;
  left: 10px;
  transform: translateY(350px);
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 180px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.dropdown-item {
  padding: 10px;
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  color: #333;
}

.dropdown-divider {
  border: none;
  border-top: 1px solid #ddd;
  margin: 8px 0;
}
