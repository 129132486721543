/* Login.module.css */

/* Import the font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Inter:wght@400;500;700&display=swap');

/* General styling */
body {
  font-family: 'Roboto', 'Inter', sans-serif; /* Set the font for the entire document */
}

.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 1rem;
}

.container {
  display: flex;
  width: 90%;
  max-width: 1200px;
  min-height: 650px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Left panel (Illustration section) styling */
.leftPanel {
  flex: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.skeletonImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  transition: background-image 0.5s ease-in-out;
  padding: 15px;
  box-sizing: border-box;
}

/* Right panel (Login form section) styling */
.rightPanel {
  flex: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.loginCard {
  max-width: 250px;
  width: 100%;
  text-align: center;
}

/* New logo styling */
.newLogo {
  margin: 0 auto 1rem;
}

.newLogo img {
  width: 150px;
  height: 75px;
}

.loginCard h2 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
  color: #5e005e;
}

.googleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.6rem;
  margin: 0.5rem 0;
  font-size: 0.75rem;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.googleLogo {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.googleBtn:hover {
  background-color: #e0e0e0;
}

.orSignin {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input {
  padding: 0.6rem;
  font-size: 0.75rem;
  font-family: 'Inter', sans-serif;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.checkboxRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7rem;
}

.forgotPassword {
  color: #5e005e;
  text-decoration: none;
  font-size: 0.7rem;
}

.forgotPassword:hover {
  text-decoration: underline;
}

.loginBtn {
  background-color: #5e005e;
  color: #fff;
  padding: 0.6rem;
  font-size: 0.75rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 0.8rem;
}

.loginBtn:hover {
  background-color: #4e004e;
}

.registerLink {
  margin-top: 0.8rem;
  font-size: 0.7rem;
}

.registerLink a {
  color: #5e005e;
  text-decoration: none;
}

.registerLink a:hover {
  text-decoration: underline;
}

.feedbackMessage {
  color: red;
  margin-top: 0.8rem;
}
