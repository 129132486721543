/* src/pages/Profile.css */

@import "~@fortawesome/fontawesome-free/css/all.min.css";


.profile-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-container h2 {
  text-align: center;
  color: #333;
}

.profile-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.avatar {
  position: relative;
  display: inline-block;
}

.avatar img.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #007bff;
}

/* Camera Icon Styling */
.camera-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-icon:hover {
  background-color: #0056b3;
}

.user-info {
  flex: 1;
}

.user-info h3 {
  margin: 0;
  color: #333;
}

.user-info p {
  color: #555;
}

.info-sections {
  display: flex;
  gap: 20px;
}

.info-box {
  flex: 1;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-box h3 {
  margin-bottom: 15px;
  color: #007bff;
}

.info-box input,
.info-box select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.profile-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.profile-container button:hover {
  background-color: #0056b3;
}
