/* Reset default margins and paddings */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Sidebar */
.sidebar {
  background-color: #333;
  color: white;
  transition: width 0.3s ease;
  height: 100vh;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 200px; /* Default expanded width */
}

.sidebar.collapsed {
  width: 60px;
}

.collapse-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin: 10px;
}

/* Content Wrapper */
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  transition: margin-left 0.3s ease;
}

/* Header */
.header {
  background-color: #007bff;
  color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  transition: margin-left 0.3s ease;
  width: 100%; /* Full width of content */
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: transparent;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Collapse sidebar automatically on smaller screens */
  .sidebar {
    width: 60px !important;
  }

  .collapse-btn {
    display: none; /* Hide collapse button on smaller screens */
  }

  /* Main content adjusts for smaller sidebar */
  .header,
  .content {
    margin-left: 60px;
  }
}
