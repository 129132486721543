/* src/pages/Home.css */

.home-container {
  padding: 20px;
}

.user-info-box {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 20px;
}

.user-info-box h3 {
  margin-top: 0;
}

.user-info-box p {
  margin: 8px 0;
}

.guest-message {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff3cd;
  margin-top: 20px;
  color: #856404;
}
