/* src/components/Header.css */

/* Main Header Styles */
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add space between title-search and button */
  background-color: #ffffff !important;
  padding: 15px;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #d6d6d6;
}

/* Container to align title and search box */
.main-header-title-search {
  display: flex;
  align-items: center;
}

/* Title Styling */
.main-header h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #515050;
  margin-right: 20px;
}

/* Search Box Styling */
.main-header-search-box {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  color: #333;
  width: 200px;
}

.main-header-search-box::placeholder {
  color: #d6d6d6;
}

/* Login/Logout Button Styling */
.auth-button {
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.auth-button:hover {
  background-color: #ff4b4b;
}
